<template>
  <div class="assignConsultArea fill-height ">
    <div class="circleOne"></div>
    <div class="circleTwo"></div>
    <div class="circleThree"></div>

    <v-app-bar class="flex-navigation" style="z-index: 2;padding:5px 0" v-if="$vuetify.breakpoint.mdAndUp" elevation="0"
               color="transparent">
      <v-container>
        <v-row no-gutters>
          <v-col class="d-flex justify-start">
          </v-col>
          <v-col class="text-center">
            <router-link :to="{name:'dashboard'}">
              <v-toolbar-title>
                <img :src="require('../../assets/simialogopng.png')" style="width: 125px;"
                     class="mt-4 mr-2" v-if="getPlatformInfo('id') == 3">
                <p v-else-if="getPlatformInfo('id') == 4">
                  {{ getPlatformTitle() }}
                </p>
              </v-toolbar-title>
            </router-link>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn class="loginBtn mt-2" elevation="0" color="" outlined to="/dashboard">
              <v-icon color="#46b1a1" small style="border: 2px solid #46b1a1;padding: 1px;border-radius: 8px"
                      class="mx-1">
                mdi-account
              </v-icon>
              {{ $t('account') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <div class="" v-else>
      <v-app-bar class="mobileAppBar" height="50">
        <v-row>
          <v-col class="text-right">
          </v-col>
          <v-col class="text-center">
            <router-link :to="{name:'dashboard'}">
              <v-toolbar-title>
                <img :src="require('../../assets/simialogopng.png')" style="width: 125px;"
                     class="mt-2 mr-2" v-if="getPlatformInfo('id') == 3">
                <p v-else-if="getPlatformInfo('id') == 4">
                  {{ getPlatformTitle() }}
                </p>
              </v-toolbar-title>
            </router-link>
          </v-col>
          <v-col :class="$vuetify.rtl ? 'text-left' : 'text-right'">
            <v-btn elevation="0" small to="/dashboard">
              <img :src="require('../../assets/new-design/loginNav.svg')" style="width: 30px;"
                   class="mt-2">
            </v-btn>
          </v-col>
        </v-row>
      </v-app-bar>
    </div>
    <v-container>
      <div class="responseArea" v-if="orderInfo">
        <div class="responseArea-inner pa-5">
          <div class="contentArea">

            <div v-if="$route.query.status == 'paid'" class="successPayment text-center " :class="classes">
              <div class="">
                <img src="../../assets/new-design/successPayment.svg" alt="">
              </div>
              <p class="successTitle mt-4">
                {{ successTitle }}
              </p>
              <div class="successItems">
                <div class="successItem">
                  <div class="d-flex justify-space-between mt-4" v-for="(item , i) in orderInfo.order.products"
                       :key="i">
                    <div class="flex-grow-1 " style="direction: ltr"
                         :class="$vuetify.rtl ? 'text-right' : 'text-right'">
                      {{ $vuetify.rtl ? item.title : item.en_title }}
                    </div>
                    <div>
                      <template v-if="item.pivot.quantity != 1">
                        <span class="">x</span>
                        <span class="">{{ item.pivot.quantity }}</span>
                        <span>({{ withCurrency(item.pivot.price, orderInfo.currency, 'long') }})</span>
                      </template>
                      <template v-else>
                        <template v-if="!orderInfo.payment.is_recurring">
                          {{ withCurrency(item.pivot.final_price, orderInfo.currency, 'long') }}
                        </template>
                        <template v-else>
                          {{ withCurrency(orderInfo.payment.cart_amount, orderInfo.currency, 'long') }}
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="finalPrice">
                <div class="d-flex justify-space-between">
                  <div>
                    {{ $t('Paid') }}
                  </div>
                  <div v-if="orderInfo.hasOwnProperty('payment') && orderInfo.payment">
                    {{ withCurrency(orderInfo.payment.paid_amount, orderInfo.currency, 'long') }}
                  </div>
                </div>
              </div>
              <v-btn class="continueBtn white--text" width="100%" elevation="0"
                     @click="toDashboard" :id="btn_id">
                {{ $t('nextLabel') }}
              </v-btn>
            </div>
            <div v-else>
              <div class="">
                <img src="../../assets/new-design/failedPayment.png" width="100%" alt="">
              </div>
              <template>
                <v-fade-transition mode="out-in">
                  <div v-if="profile && transactionCurrency" :key="1">
                    <template v-if="transactionCurrency == 'rial'">
                      <div class="text-center mt-4 failedTitle">
                        {{ $t('PaymentFailed') }}
                      </div>
                      <div class="failedDeac" v-html="$t('paymentFailedDesc')"></div>
                    </template>
                    <template v-else-if="profile.leadUser">
                      <div class="failedTitle">
                        {{ $t('assignconsult.sorry') }}
                      </div>
                      <p class="description text-center mt-6">
                        {{ $t('assignconsult.newUserTexts[0]') }}
                        <br>
                        {{ $t('assignconsult.newUserTexts[1]') }}
                      </p>
                      <div class="d-flex justify-center">
                        <a style="text-decoration: none" href="https://telegram.me/simiaroom_maryamkhazraee">
                          <v-btn color="blue" fab class="white--text mx-1 text-center" elevation="0" x-small>
                            <v-avatar size="25">
                              <img src="../../assets/tests/telegram.png" width="" alt="">
                            </v-avatar>
                          </v-btn>
                        </a>
                        <a style="text-decoration: none" href="https://wa.me/00989302672054">
                          <v-btn color="green" fab class="white--text mx-1 text-center" elevation="0" x-small>
                            <v-icon>mdi-whatsapp</v-icon>
                          </v-btn>
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div class="failedTitle">
                        {{ $t('assignconsult.sorry') }}
                      </div>
                      <p class="description text-center mt-6">
                        {{ $t('assignconsult.oldUserTexts[0]') }}
                        <br>
                        {{ $t('assignconsult.oldUserTexts[1]') }}
                      </p>
                      <div class="d-flex justify-center">
                        <a style="text-decoration: none" href="https://telegram.me/simiaroom_maryamkhazraee">
                          <v-btn color="blue" fab class="white--text mx-1 text-center" elevation="0" x-small>
                            <v-avatar size="25">
                              <img src="../../assets/tests/telegram.png" width="" alt="">
                            </v-avatar>
                          </v-btn>
                        </a>
                        <a style="text-decoration: none" href="https://wa.me/00989302672054">
                          <v-btn color="green" fab class="white--text mx-1 text-center" elevation="0" x-small>
                            <v-icon>mdi-whatsapp</v-icon>
                          </v-btn>
                        </a>
                      </div>
                    </template>
                  </div>
                  <div :key="2" v-else class="text-center mt-10">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                </v-fade-transition>
              </template>
              <!--                {{$t('PaymentFailed')}}-->
              <!--              <div class="failedDeac" v-html="$t('paymentFailedDesc')"></div>-->
              <v-btn class="continueBtn white--text" width="100%" elevation="0" color="" @click="toFactor">
                {{ $t('confirm') }}
              </v-btn>
            </div>
          </div>

        </div>
      </div>
    </v-container>

  </div>
</template>

<script>
/*eslint-disable*/
// import Vue from 'vue';
// import Tracker from '../../tracker';

import afterPaymentMixins from "@/mixins/afterPaymentMixins";
import {mapGetters} from "vuex";

export default {

  components: {},
  mixins: [afterPaymentMixins],
  computed: {
    ...mapGetters({
      'AppUrl': 'getAppUrl',
    }),
    suggestedDays() {
      return this.days;
    },
    getLang() {
      return localStorage.getItem('local') || "fa"
    },
    successTitle() {
      if (this.getLang == 'fa') {
        return "پرداخت موفق"
      }
      return "Payment Was Successful"
    },
    action() {
      if (this.getLang == 'fa') {
        return " تایید"
      }
      return "confirm"
    },
    codeTrackingCode() {
      if (this.getLang == 'fa') {
        return "  شماره پیگیری"
      }
      return "Tracking Code"
    },
    failedTitle() {
      if (this.getLang == 'fa') {
        return "پرداخت با شکست مواجه شد"
      }
      return "Payment failed"
    }
  },
  mounted() {
    this.requesting('auth', 'getProfile').then(() => {
      this.profile = this.$store.getters.getUserInfo;
      this.processPayment()
      this.checkSuccessPayment();
    })
    this.requesting('auth', 'platformInfo')
  },
  watch: {
    paymentDialog(newVal) {
      if (!newVal) {
        this.redirectToStepper();
      }
    }
  },
  methods: {
    toDashboard() {

      if (this.isNewCoustomer) {
        window.location.href = `${this.AppUrl}/fa/onboarding-new`
      } else {
        window.location.href = `${this.AppUrl}/fa/panel/dashboard`
      }
    },
    toFactor() {
      this.$router.push(`/${this.$vuetify.rtl ? 'fa' : 'en'}/panel/dashboard`)
    },
    processPayment() {
      if (this.$route.query.status == "paid") {
        // this.transactionClass = 'success';
        this.transactionClass = 'green--text';
        this.message = this.successTitle;
        this.btn_id = 'ga_purchase_successful';
      } else {
        this.btn_id = 'ga_purchase_failed';
        this.transactionClass = 'red--text';
        this.transactionRefId = null;
        this.message = this.failedTitle;
        this.validOrder = false;
      }
      this.paymentDialog = true
      this.transactionRefId = this.$route.query.ref_id || null;
      this.successfulPayment.trCode = this.$route.query.trCode || null;
    },

    // sendAgain() {
    //     this.$gtm.trackEvent(this.paydata);
    // },
    getCookie: function (cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    redirectToStepper() {
      // this.$router.push({path: this.getLang+'/panel/dashboard'});
      return;
    },


    checkSuccessPayment() {
      if (this.$route.query.hasOwnProperty('successful_payment')) {
        this.requesting('payment', 'getPaymentInfo', {
          paymentID: this.$route.query.successful_payment
        }).then((resp) => {
          this.isNewCoustomer = resp.data.newCustomer
          this.transactionCurrency = resp.data.currency
          this.prepareDataForWebengageAndSend(resp)
        }).catch(err => {
          // console.log(err)
        })
      } else {
        this.redirectToStepper();
        return;
      }
    },
  },
  data() {
    return {
      transactionCurrency: null,
      isNewCoustomer: null,
    }
  }
}
</script>

<style>
@media (min-width: 200px) and (max-width: 600px) {
  .circleOne {
    width: 300px !important;
    height: 300px !important;
    top: -150px !important;
    left: -160px !important;
  }

  .contentArea {
    width: 80% !important;
  }

}

.description {
  font-size: 9pt;
  line-height: 20pt;
}

.assignConsultArea {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

.circleOne {
  background: #dff8ff;
  z-index: 0;
  width: 800px;
  height: 800px;
  border-radius: 50%;
  top: -300px;
  left: -300px;
  position: absolute;
  filter: blur(20px);
}

.circleTwo {
  position: absolute;
  background: #f0f1ff;
  bottom: -250px;
  left: -140px;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  filter: blur(20px);
}

.circleThree {
  position: absolute;
  background: #e0fff6;
  bottom: 200px;
  right: -150px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  filter: blur(20px);
}

.loginBtn {
  width: 120px !important;
  height: 34px !important;
  padding: 5px;
  border-radius: 10px;
  border: solid 1px #46b1a1 !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #46b1a1 !important;
}

.mobileAppBar {
  box-shadow: 0 3px 17px 0 rgba(0, 0, 0, 0.16);
}

.responseArea {
  position: relative;
}

.responseArea-inner {
  position: absolute;
  top: 0px;
  border-radius: 20px;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  right: 0px;
  left: 0px;
}

.successPayment img {
  width: 100%;
}

.contentArea {
  width: 32%;
  margin: auto;
}

.successPayment {

}

.successPayment .successTitle {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #1cba88;
}

.successItems {
  margin-top: 80px;
}

.finalPrice {
  border-radius: 10px;
  color: #3cbe8b;
  text-align: center;
  margin-top: 25px;
  padding: 15px;
  background: #F3FCF9;
}

.continueBtn {
  height: 50px !important;
  padding: 15px !important;
  margin-top: 50px !important;
  border-radius: 10px;
  background-color: #4576d8 !important;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.failedTitle {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #fe6b2d;
  margin-top: 20px;
}

.failedDeac {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin-top: 30px;
}

.returnBtn {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8 !important;
  height: 50px !important;
  border-radius: 10px;
  border: solid 1px #4576d8 !important;
  background: #fff !important;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
}

</style>